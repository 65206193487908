/**
 * @generated SignedSource<<f4f65bbdb8a4683e8b244c7cdabfb3cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type unified_ingest_transform_templates_get_params = {
  owner_name: string;
  target_table?: string | null;
};
export type SelectDatasetTemplateQuery$variables = {
  params: unified_ingest_transform_templates_get_params;
};
export type SelectDatasetTemplateQuery$data = {
  readonly unified_ingest_transform_templates_get: {
    readonly source_systems: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly templates: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
    }>;
  };
};
export type SelectDatasetTemplateQuery = {
  response: SelectDatasetTemplateQuery$data;
  variables: SelectDatasetTemplateQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "unified_ingest_transform_templates_get_output",
    "kind": "LinkedField",
    "name": "unified_ingest_transform_templates_get",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "unified_ingest_transform_templates_source_system",
        "kind": "LinkedField",
        "name": "source_systems",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "unified_ingest_transform_templates_template",
            "kind": "LinkedField",
            "name": "templates",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectDatasetTemplateQuery",
    "selections": (v3/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelectDatasetTemplateQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "fd295cb03b0e03989f49c4d772c5d2bd",
    "id": null,
    "metadata": {},
    "name": "SelectDatasetTemplateQuery",
    "operationKind": "query",
    "text": "query SelectDatasetTemplateQuery(\n  $params: unified_ingest_transform_templates_get_params!\n) {\n  unified_ingest_transform_templates_get(params: $params) {\n    source_systems {\n      id\n      name\n      templates {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "96141041b381578a4b80bcbb3c8f6c71";

export default node;
